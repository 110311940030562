import "../styles/bal.css";

import LinkBack from "../components/link-back";
import Link from "../components/link";

import acifront from "../assets/webproject/aci/aci-frontimg.jpeg";
import aci1 from "../assets/webproject/aci/aci-1.webp";
import aci2 from "../assets/webproject/aci/aci-2.webp";
import aci3 from "../assets/webproject/aci/aci-3.webp";
import aci11 from "../assets/webproject/aci/aci-11.webp";
import aci12 from "../assets/webproject/aci/aci-12.webp";

import React, {useEffect, useRef} from 'react';

import { ReactLenis, useLenis } from 'lenis/react';

function Aci(props) {
    const lenis = useLenis(({ scroll }) => {})
    
    document.body.style.backgroundColor = "var(--acicalese)";
    document.body.style.setProperty("--scrollbar-background", "var(--acicalese)");
    document.body.style.setProperty("--scrollbar-border", "var(--acicalese)");

    useEffect(() => {
        window.scroll(0, 0)
    })

    return <ReactLenis root>
        <div className="bal">
            <LinkBack link="/home">Zurück</LinkBack>

            <div className="pGrid">
                <div className="pHead" style={{
                    backgroundColor: "var(--acicalese)"
                }}>
                    <h3>ANDREA CICALESE</h3>
                    <h4>HOMEPAGE</h4>
                </div>
                <div className="pTextWrapper" style={{
                    backgroundColor: "var(--acicalese)"
                }}>
                    <p>Er ist ein junger aufstrebender Violinist, der mit Konzerten in den USA und ganz Europa tausende Zuschauer mit seiner Musik begeistert. Um Andrea Cicalese auch im Internet eine große Bühne zu geben, haben wir seine Website von Grund auf erneuert und zur zentralen Anlaufstelle seiner internationalen Fans gemacht.<br/><br/>Mein Aufgabenbereich: Konzeption & UI Design / Prototyping</p>
                    <Link link="https://andrea-cicalese.com/" >Zur Website</Link>
                </div>
                <div className="pFrontImg" style={{
                    background: "url('" + acifront + "') no-repeat center",
                    backgroundSize: "cover"
                }}/>

                <div className="p15 pFullHeight" style={{
                    background: "var(--black-sand) url('" + aci1 + "') no-repeat center",
                    backgroundSize: "contain"
                }}/>

                <div className="p15" style={{
                    background: "var(--black-sand) url('" + aci11 + "') no-repeat center center",
                    backgroundSize: "contain"
                }}/>

                <div className="p15" style={{
                    background: "var(--black-sand) url('" + aci12 + "') no-repeat center center",
                    backgroundSize: "contain"
                }}/>

                <div className="p15 pFullHeight" style={{
                    background: "var(--black-sand) url('" + aci2 + "') no-repeat center",
                    backgroundSize: "contain"
                }}/>

                <div className="p15 pFullHeight" style={{
                    background: "var(--black-sand) url('" + aci3 + "') no-repeat center",
                    backgroundSize: "contain"
                }}/>

            </div>

            <div className="pNextLink">
                <Link link="/2" >Zum nächsten Projekt</Link>
            </div>

        </div>
    </ReactLenis>
}

export default Aci;