import "../styles/home.css";

import Head from "../components/head";
import Nav from "../components/nav";
import Headline from "../components/headline";
import WebProject from "../components/webProject";
import Concept from "../components/concept";
import Gallery from "../components/gallery";
import Footer from "../components/footer";

import andrea from "../assets/webproject/aci/aci.webp";
import bergamlaim from "../assets/webproject/bal/bal.webp";
import limeboard from "../assets/webproject/lime/lime.svg";
import jjbfilms from "../assets/webproject/jjb/jjb.webp";
import solveig from "../assets/webproject/sol/sol.webp";
import cuodex from "../assets/webproject/cdx/cdx.webp";

import c1 from "../assets/concept/c1.svg";
import c2 from "../assets/concept/c2.webp";
import c3 from "../assets/concept/c3.webp";
import c4 from "../assets/concept/c4.svg";
import c5 from "../assets/concept/c5.webp";
import c6 from "../assets/concept/c6.svg";

import g1 from "../assets/graphic/cdxicon.svg";
import g2 from "../assets/graphic/belacarl.webp";
import g3 from "../assets/graphic/bwk.webp";
import g4 from "../assets/graphic/passx.webp";
import g5 from "../assets/graphic/cdxshow.svg";
import g6 from "../assets/graphic/jgsv.webp";

import React, {useEffect, useRef} from 'react';

import { ReactLenis, useLenis } from 'lenis/react';
import { useLocation } from "react-router-dom";


function Home(props) {

    document.body.style.backgroundColor = "var(--paper)";

    const lenis = useLenis(({ scroll }) => {});

    const location = useLocation();
    useEffect(() => {
        window.scroll(0, props.scrollY)
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            //console.log("removeEventListener")
        }
    });
    useEffect(() => {
        
      },[
        location
      ]
    )
    function handleScroll(e){
        //console.log(location.pathname)
        if(!location.pathname.includes("home")) return;
        window.dispatchEvent(new CustomEvent("updateScrollY", {
            detail: {
                value: window.scrollY
            }
        }))
    }

    return (
        <ReactLenis root>
            <Head/>

            {/* 
            <div style={{
                position: "fixed",
                bottom: "0",
                left: "0",
                backgroundColor: "var(--black-sand)",
                width: "calc(100% - 2 * var(--body-padding))",
                padding: "30px calc(var(--body-padding) * 1.5)",
                zIndex: "999",
            }}>
                <h5 style={{color: "var(--paper)", paddingBottom: "12px"}} >Titel</h5>
                <p style={{color: "var(--paper)" }}>Nachricht</p>
            </div>
             */}

            <Nav active={props.active} />

            <Headline>Webdesign Projekte</Headline>

            <WebProject color="acicalese" subhead="Homepage" image={andrea} moreLink="/1" websiteLink="https://andrea-cicalese.com/">Andrea Cicalese</WebProject>
            <WebProject color="violet" subhead="Homepage & Dashboard" image={bergamlaim} moreLink="/2" websiteLink="https://bergamlaimer.info/">BergamLaimer<wbr/>.info</WebProject>
            <WebProject color="cactus" subhead="Web App" image={limeboard} moreLink="/3">LIMEBOARD</WebProject>
            <WebProject color="strawberry" subhead="Homepage & ci" image={jjbfilms} moreLink="/4" websiteLink="https://jjb-films.de/">JJB Films</WebProject>
            <WebProject color="blueberry" subhead="Homepage" image={solveig} moreLink="/5">Solveig Tanztherapie</WebProject>
            <WebProject color="silver" subhead="Homepage" image={cuodex} moreLink="/6">Cuodex</WebProject>
            
            <Headline>Konzeptstudien</Headline>

            <div className="secConcept">

                <Concept image={c1} headline={"Snippet Gallery"} >Plattform für Code-Ausschnitte.<br/>Flat Design, Farbverlauf<br/>09/2022</Concept>
                
                <Concept image={c2} headline={"Through our own eyes"} >Homepage.<br/>Typografie, Einfarbigkeit<br/>2020</Concept>

                <Concept image={c3} headline={"The best way to do it"} >Product Page.<br/>Hero Section, Typografie<br/>06/2024</Concept>

                <Concept image={c4} headline={"Imagine"} >Homepage.<br/>Footer, Call to Action<br/>2020</Concept>

                <Concept image={c5} headline={"Cuodex Homepage Konzept"} >Landing Page.<br/>Hero Section, dunkles Design<br/>2021</Concept>

                <Concept image={c6} headline={"Legal / Privacy Policy"} >Portfolio.<br/>Freihand-Formen, Card-Slider, Footer<br/>2020</Concept>
                
            </div>

            <Headline>Grafik</Headline>

            <div className="secConcept">

                <Concept image={g1} headline={"Cuodex Graphics"} >Schmückende Grafik für die Datenschutz- / Nutzungs&shy;bedingungs&shy;seite von cuodex.net. Die Grafik besteht aus einer Auswahl verschie&shy;dener thematisch passender Symbole im Farb&shy;bereich Blau bis Grün.<br/>05/2022</Concept>
                
                <Concept image={g2} headline={"Brichzin & Wagner Konzert"} >Plakat.<br/>Sommerkonzert Akkordeon und Viola.<br/>07/2023</Concept>

                <Concept image={g3} headline={"Traumfabrik"} >Hüllencover.<br/>Cover für die DVD (bzw. USB) -Hüllen des Films zur Akrobatikshow der AG "Bewegungskünste" des Michaeli-Gymnasiums München. Thema des Auftritts: "Traumreise im Spiel der Gefühle"<br/>02/2023</Concept>

                <Concept image={g4} headline={"PassX Instagram Story"} >Instagram Story.<br/>Vorstellung eines neuen Features.<br/>2020</Concept>

                <Concept image={g5} headline={"PassX Features"} >Infografik.<br/>Infografik für den Passwort-Manager "PassX". Thema: "verschüsselte Übertragung", "Nutzungsmöglichkeit über mehrere Geräte", "übersichtliches Interface".<br/>02/2022</Concept>

                <Concept image={g6} headline={"Jgsv Live"} >Livestream.<br/>Hintergrund, Intro-Ani&shy;mation, Moder&shy;ations&shy;karten, PPT-Style Guide für die live über&shy;tragene Jahr&shy;gangs&shy;stufen&shy;versamm&shy;lung 22/23 am Michaeli-Gym&shy;nasium München. Die Grafik zeigt eine stili&shy;sierte West&shy;ansicht des Haupt&shy;gebäudes.<br/>09/2022</Concept>
                
            </div>

            <Headline>Fotografie</Headline>

            <div>
                <Gallery/>

                <div className="homeFooter">
                    <Footer link="/cv" linktext="Zum Lebenslauf"/>
                </div>
            </div>

        </ReactLenis>
    )
}

export default Home;